import React, { useState } from "react";
import useAxios, { configure } from "axios-hooks";
import { toast } from "react-toastify";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import { $authHost, axiosConfig } from "../../common/api";
import { isOtpCode } from "../../common/utils";

const ModalOtpAccept = ({ active, setActive, dealId, state }) => {
  const [otpCode, setOtpCode] = useState("");

  configure({ axios: $authHost });

  useGlobalScrollLock(active);

  const [{ loading: loadingStatus }, executePostStatus] = useAxios(
    {
      url: "/trader/deals/changestatus",
      method: "POST",
    },
    axiosConfig
  );

  const handleChangeStatusDeal = () => {
    executePostStatus({
      data: {
        deal_id: dealId,
        otp_code: otpCode,
        state: state,
      },
    })
      .then((data) => {
        handleOnClose();
        toast.success("Статус сделки успешно изменён");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  const handleOnClose = () => {
    setOtpCode("");
    setActive(false);
  };

  let errors = {
    verify_2fa: isOtpCode(otpCode)
      ? null
      : {
          message: "Допустимо только 6 цифр",
        },
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal9"
      onClick={handleOnClose}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          {state === "close" && (
            <div className="dialog__head-title">Закрыть сделку</div>
          )}
          {state === "archive" && (
            <div className="dialog__head-title">Отправить сделку в архив</div>
          )}

          <div
            className="dialog__head-close"
            id="modal9Close"
            onClick={handleOnClose}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        {
          <div
            className="dialog__body"
            style={{
              textAlign: "left",
              overflow: "auto",
              maxHeight: "calc(100vh - 230px)",
            }}
          >
            {
              <>
                <span>Введи 6-ти значный OTP код</span>
                <div className="custom-input custom-input_block">
                  <label htmlFor="verify-2fa">Код OTP</label>
                  <input
                    name="verify-2fa"
                    type="text"
                    id="verify-2fa"
                    placeholder="123123"
                    autoComplete="off"
                    onChange={(e) => setOtpCode(e.target.value)}
                    value={otpCode}
                  />
                  {getErrorBlock(errors?.verify_2fa)}
                </div>
                <button
                  className="btn btn-success"
                  disabled={loadingStatus || errors?.verify_2fa}
                  onClick={() => {
                    handleChangeStatusDeal();
                  }}
                  style={{ marginTop: 40 }}
                >
                  Подтвердить
                </button>
              </>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default ModalOtpAccept;
