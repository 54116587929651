import axios from "axios";
import { API_URL } from "./constants";

const config = {
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

const getAuthToken = () => {
  if (localStorage.getItem("authToken")) {
    return localStorage.getItem("authToken");
  }
  return null;
};

const $host = axios.create(config);

const $authHost = axios.create(config);

const authInterseptors = (config) => {
  if (getAuthToken()) {
    config.headers.authorization = `Bearer ${getAuthToken()}`;
  }
  return config;
};

$authHost.interceptors.request.use(authInterseptors);

const axiosConfig = { manual: true, useCache: false, ssr: false };

export { $host, $authHost, getAuthToken, axiosConfig };
