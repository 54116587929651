import React from "react";
import AnimatedBlock from "../animatedblock/AnimatedBlock";
import "./Spinner.css";

const Spinner = () => {
  return (
    <AnimatedBlock>
      <div className="spinner"></div>
    </AnimatedBlock>
  );
};

export default Spinner;
