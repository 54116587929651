import { useSpring, animated } from "@react-spring/web";

export default function AnimatedBlock({ children }) {
  const [props, api] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    []
  );

  return (
    <animated.div style={{ ...props, ...{ width: "100%" } }}>
      {children}
    </animated.div>
  );
}
