import { $host, $authHost, getAuthToken } from "./api";
import jwt_decode from "jwt-decode";

export const setRegistration = async (login, password, email, rc, isTrader) => {
  if (login && password && email && rc) {
    const { data } = await $host.post("/merchant/auth/reg", {
      login,
      password,
      email,
      rc,
      isTrader,
    });
    localStorage.setItem("authToken", data.token);
    return jwt_decode(data.token);
  } else {
    return null;
  }
};

export const setLogin = async (login, password, rc, isTrader) => {
  if (login && password && rc) {
    const { data } = await $host.post("/merchant/auth/login", {
      login,
      password,
      rc,
      isTrader,
    });
    localStorage.setItem("authToken", data.token);
    return jwt_decode(data.token);
  } else {
    return null;
  }
};

export const setLogout = () => {
  localStorage.removeItem("authToken");
};

export const isLogin = () => {
  return getAuthToken() ? true : false;
};

export const setChangePassword = async (newpass) => {
  const { data } = await $authHost.post("/merchant/auth/changepass", {
    newpass,
  });
  return data;
};
