import React, { useState } from "react";
import { toast } from "react-toastify";
import { setChangePassword } from "../common/userApi";
import { TRADE_MODE } from "../common/constants";
import TraderSettingsOtp from "./TraderSettingsOtp";
// import TraderSettingsKeys from "./TraderSettingsKeys";

const Settings = (props) => {
  const { user } = props;

  const [newPassword, setNewPassword] = useState("");

  const handleClick = async (newpass) => {
    setChangePassword(newpass)
      .then((data) => {
        toast.success("Пароль успешно изменён");
        setNewPassword("");
      })
      .catch((error) => toast.error(error?.response?.data?.error));
  };

  return (
    <div>
      <div className="text text_lg">{user?.login}</div>
      <div className="section-settings">
        <div className="card-block mt-25">
          <h4>Изменение пароля</h4>
          <div className="custom-input custom-input_block">
            <label htmlFor="change-password">Новый пароль</label>
            <input
              name="password"
              type="text"
              id="change-password"
              placeholder="***"
              autoComplete="off"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
            />
          </div>
          <button
            className="btn btn-success btn_sm custom-input_block-btn"
            onClick={() => handleClick(newPassword)}
            style={{ position: "relative", marginTop: 20, maxWidth: 120 }}
          >
            Сохранить
          </button>
        </div>
      </div>
      {TRADE_MODE && (
        <>
          <TraderSettingsOtp />
        </>
      )}
    </div>
  );
};

export default Settings;
