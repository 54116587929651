import React from "react";
import "./LinearProgressBar.css";

const PERCENT_WARNING = 80;

const getPercentage = (current, limit) => Math.round((current / limit) * 100);

const LinearProgressBar = ({ currentValue, limitValue }) => {
  const percent = getPercentage(currentValue, limitValue);

  const progressBarClass =
    percent <= PERCENT_WARNING ? "fillingProgressBar" : "warningProgressBar";

  const progressBarStyle = {
    left: percent - 100 + "%",
    transition: "2s",
  };

  return (
    <div className="mainProgressBarContainer">
      <div className="emptyProgressBar">
        <div className={progressBarClass} style={progressBarStyle} />
      </div>
    </div>
  );
};

export default LinearProgressBar;
