export const formatFinanceString = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\u00A0");
};

export const formatFinance = (num) => {
  if (num === undefined || num === null) {
    return null;
  } else {
    if (typeof num === "number") {
      if (num === 0.0) {
        return "0";
      }
      let formattedNum = num.toFixed(2);
      if (formattedNum.endsWith(".00")) {
        formattedNum = formattedNum.substring(0, formattedNum.length - 3);
      }
      return formatFinanceString(formattedNum);
    }
    return formatFinanceString(num);
  }
};

export const truncateString = (str, maxLength = 8) => {
  if (str.length <= maxLength) return str;
  return str.toString().replace(new RegExp(`^(.{${maxLength - 2}}).*`), "$1**");
};

export const truncateStartString = (str, maxLength = 6) => {
  if (str.length <= maxLength) return str;
  return "**" + str.toString().slice(-1 * (maxLength - 2));
};

export const getShortRequisiteData = (note) => {
  try {
    if (note) {
      const data = JSON.parse(note);
      if (data?.pan) {
        return truncateStartString(data?.pan);
      } else if (data?.cardpan) {
        return truncateStartString(data?.cardpan);
      } else if (data?.phone) {
        return truncateStartString(data?.phone);
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getNormalRequisiteData = (note) => {
  try {
    const data = note ? JSON.parse(note) : null;

    if (!data) return "";

    switch (data?.type) {
      case "sbp":
        return normalPhone(data?.pan);
      case "card":
        return normalCard(data?.pan);
      default:
        return "";
    }
  } catch (error) {
    return null;
  }
};

export const getFioRequisiteData = (note) => {
  try {
    const data = note ? JSON.parse(note) : null;

    if (!data) return "";
    return data?.fio || "";
  } catch (error) {
    return null;
  }
};

export const getMainRequisiteData = (data) => {
  if (data) {
    if (data?.pan) {
      return normalCard(data.pan);
    } else if (data?.cardpan) {
      return normalCard(data.cardpan);
    } else if (data?.phone) {
      return normalPhone(data.phone);
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getMainRequisiteProfileData = (data) => {
  if (data) {
    return normalCard(data);
  } else {
    return null;
  }
};

export const getMainDeviceProfileData = (data) => {
  return null;
};

export const isOtpCode = (str) => {
  if (str === "") return true;
  return /^\d{6}$/.test(str);
};

export const isPhoneNumber = (str) => {
  if (str === "") return true;
  return /^\+7\d{10}$/.test(str);
};

export const isCardNumber = (str) => {
  if (str === "") return true;
  return /^\d{16}$/.test(str);
};

export const isNumeric = (str) => {
  if (str === "") return true;
  return /^\d+([.]\d{0,2})?$/.test(str);
};

export const isPdfPath = (str) => {
  return /.pdf$/.test(str);
};

export const normalCard = (card) => {
  return String(card).replace(/(\d)(?=(\d\d\d\d)+([^\d]|$))/g, "$1 ");
};

export const normalPhone = (phone) => {
  return String(phone).replace(
    /(\d)(\d{3})(\d\d\d)(\d\d)(\d\d)/g,
    "$1 $2 $3 $4 $5"
  );
};

export const formatMaskPhoneNumber = (phoneNumber) => {
  const digits = phoneNumber.replace(/[^\d+]/g, "");
  const match = digits.match(/^\+7(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+7 ${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
  } else {
    return null;
  }
};
