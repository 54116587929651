import React, { useState } from "react";
import { toast } from "react-toastify";

const Wallet = (props) => {
  const { user } = props;

  return (
    <div>
      <div className="text text_lg">Кошелек</div>
      <div className="section-about">
        {
          // Добавить варианты вывода
        }
      </div>
    </div>
  );
};

export default Wallet;
