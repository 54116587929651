import React from "react";

import { useNavigate } from "react-router-dom";
import useGlobalScrollLock from "../hooks/useGlobalScrollLock";
import { TelegramIcon } from "./Icons";

const Header = ({
  hamburgerActive,
  setHamburgerActive,
  supportTelegramLink,
}) => {
  const navigate = useNavigate();

  useGlobalScrollLock(hamburgerActive);

  const handleDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <header>
      <input
        id="menu__toggle"
        type="checkbox"
        checked={hamburgerActive}
        onChange={() => setHamburgerActive(!hamburgerActive)}
      />
      <label className="menu__btn" htmlFor="menu__toggle">
        <span></span>
      </label>

      <div
        className="logo"
        style={{ cursor: "pointer", textShadow: "1px 3px 1px black" }}
        onClick={handleDashboard}
      >
        RosPlat
      </div>

      <a
        href={supportTelegramLink}
        target="_blank"
        rel="noopener noreferrer"
        className="contact-us"
      >
        <TelegramIcon />
        <span>Связь с тех. поддержкой</span>
      </a>
    </header>
  );
};

export default Header;
