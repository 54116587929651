import React, { useState } from "react";
import "./ToggleButton.css";

const ToggleButton = ({ onToggle, initialState = false }) => {
  const [isToggled, setToggled] = useState(initialState);

  const handleToggle = () => {
    const newState = !isToggled;
    setToggled(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <button
      className={`toggle-button ${isToggled ? "toggle-button--toggled" : ""}`}
      onClick={handleToggle}
    >
      <div className="toggle-button__slider" />
    </button>
  );
};

export default ToggleButton;
