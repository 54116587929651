import { useEffect } from "react";
import "dayjs/locale/ru";
import * as dayjs from "dayjs";

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

const convertSecondsToTimeString = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  if (minutes > 59) return `> 1ч`;
  return `${formattedMinutes}:${formattedSeconds}`;
};

const useDayjsFormat = () => {
  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale("ru");
  }, []);

  const getFullDate = (dateValue) => {
    return dayjs(dateValue).format("D MMMM YYYY");
  };

  const getFullTime = (dateValue) => {
    return dayjs(dateValue).format("HH:mm:ss");
  };

  const getFullDateMsk = (dateValue) => {
    return dayjs(dateValue).tz('Europe/Moscow').format("D MMMM YYYY");
  };

  const getFullTimeMsk = (dateValue) => {
    return dayjs(dateValue).tz('Europe/Moscow').format("HH:mm:ss");
  };

  const isDeltaPartSecond = (dateCValue, dateRValue) => {
    const datePing = dayjs(dateCValue);
    const dateNow = dayjs(dateRValue);
    const differenceInDays = dateNow.diff(datePing, "second");
    return convertSecondsToTimeString(parseInt(differenceInDays));
  };

  const isDeltaMinute = (dateValue) => {
    const datePing = dayjs(dateValue);
    const dateNow = dayjs();
    const differenceInDays = dateNow.diff(datePing, "minute");
    return parseInt(differenceInDays) > 0 ? true : false;
  };

  return { getFullDate, getFullTime, getFullDateMsk, getFullTimeMsk, isDeltaMinute, isDeltaPartSecond };
};

export default useDayjsFormat;
