import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { isOtpCode } from "../common/utils";

const TraderSettingsOtp = () => {
  const [stateOtp, setStateOtp] = useState(null); // init2fa prepare2fa, enabled2fa
  const [codeOtp, setCodeOtp] = useState("");
  const [otpSecret, setOtpSecret] = useState("");
  const [otpUrl, setOtpUrl] = useState("");

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/trader/settings/otp`,
      method: "POST",
    },
    axiosConfig
  );

  const handleVerifyOtp = () => {
    executePost({
      data: {
        state_otp: stateOtp,
        code_otp: codeOtp,
      },
    })
      .then((data) => {
        if (data?.data?.otpSecret) {
          setOtpSecret(data?.data?.otpSecret);
        }
        if (data?.data?.otpUrl) {
          setOtpUrl(data?.data?.otpUrl);
        }
        if (data?.data?.otpState) {
          setStateOtp(data?.data?.otpState);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (!stateOtp) {
      executePost({
        data: {
          state_otp: stateOtp,
          code_otp: codeOtp,
        },
      })
        .then((data) => {
          if (data?.data?.otpState) {
            setStateOtp(data?.data?.otpState);
          }
          if (data?.data?.otpState) {
            setStateOtp(data?.data?.otpState);
          }
          if (data?.data?.otpState) {
            setStateOtp(data?.data?.otpState);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error);
        });
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [stateOtp]);

  let errors = {
    verify_2fa: isOtpCode(codeOtp)
      ? null
      : {
          message: "Допустимо только 6 цифр",
        },
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };
  return (
    <div className="section-settings">
      <div className="card-block mt-25">
        {stateOtp === "init2fa" && (
          // state init2fa
          <>
            <h4>Двухэтапная аутентификация</h4>
            <span>Текущий статус: отключено</span>
            <button
              className="btn btn-success btn_sm custom-input_block-btn"
              onClick={() => {
                handleVerifyOtp();
                //setStateOtp("prepare2fa")
              }}
              style={{ position: "relative", marginTop: 20, maxWidth: 120 }}
            >
              Включить
            </button>
          </>
        )}

        {stateOtp === "prepare2fa" && (
          // step prepare2fa
          <>
            <h4>Установка двухэтапной аутентификации</h4>
            <span>
              1. Сканируй или скопируй код 2FA в приложение Google
              Authenticator.
            </span>
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 210,
                width: "100%",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={otpUrl || ""}
                viewBox={`0 0 256 256`}
              />
            </div>

            <div className="custom-input custom-input_block">
              <label htmlFor="code-2fa">Секретный код 2FA</label>
              <input
                disabled
                name="code-2fa"
                type="text"
                id="code-2fa"
                autoComplete=""
                value={otpSecret || ""}
              />
            </div>
            <span></span>

            <span>
              2. Введи 6-ти значный OTP код из своего приложения аутентификации.
            </span>
            <div className="custom-input custom-input_block">
              <label htmlFor="verify-2fa">Код OTP</label>
              <input
                name="verify-2fa"
                type="text"
                id="verify-2fa"
                placeholder="123123"
                autoComplete="off"
                onChange={(e) => setCodeOtp(e.target.value)}
                value={codeOtp || ""}
              />
              {getErrorBlock(errors?.verify_2fa)}
            </div>
            <button
              disabled={errors?.verify_2fa || loading}
              className="btn btn-success custom-input_block-btn"
              onClick={() => {
                handleVerifyOtp();
                //setStateOtp("enabled2fa")
              }}
              style={{ position: "relative", marginTop: 40, maxWidth: 120 }}
            >
              Сохранить
            </button>
          </>
        )}

        {stateOtp === "enabled2fa" && (
          // step enabled2fa
          <>
            <h4>Двухэтапная аутентификация</h4>
            <span>Текущий статус: Включена</span>
            <span>Отключение возможно только через техническую поддержку</span>
          </>
        )}
      </div>
    </div>
  );
};

export default TraderSettingsOtp;
